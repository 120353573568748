import { Close, Https, Star } from "@mui/icons-material";
import { CardActionArea, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Pagination, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

export default function AllImagesDialog(props) {
    const [categorySelected, setCategorySelected] = useState('all')
    const [sortByPrice, setSortByPrice] = useState('none')
    const [showFavorites, setShowFavorites] = useState(false)

    const [pageSelected, setPageSelected] = useState(1)
    const [categories, setCategories] = useState([])
    const perPage = 9

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        // window listener
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });

        setWindowWidth(window.innerWidth);

        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth);
            });
        }
    }, []);


    useEffect(() => {
        if (props.open) {
            setCategorySelected('all')
            setSortByPrice('none')
            setShowFavorites(false)

            if (categories.length === 0) {
                loadCategories()
            }
        }
    }, [props.open])

    const loadCategories = () => {
        axios.get('https://brainteaser.codersuccess.com/api/popup/getImageCategories')
            .then((response) => {
                setCategories(response.data)
            });
    }

    return <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
        <DialogTitle style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                paddingLeft: 10,
            }}>
                All Images
                <IconButton color="error" onClick={props.onClose}>
                    <Close />
                </IconButton>
            </div>
            <div style={{ paddingInline: 10 }} className="row m-0 mt-2 align-items-center justify-content-between d-flex">
                <div className="row m-0 p-0 align-items-center d-flex" style={{ width: "fit-content" }}>
                    <FormControl style={{ width: windowWidth < 768 ? 100 : 200 }}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={categorySelected}
                            label="Category"
                            onChange={(e) => setCategorySelected(e.target.value)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {categories.map((item, index) => {
                                return <MenuItem key={index} value={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <FormControl style={{ width: windowWidth < 768 ? 140 : 200, marginLeft: 20 }}>
                        <InputLabel>Sort by price</InputLabel>
                        <Select
                            value={sortByPrice}
                            label="Sort by price"
                            onChange={(e) => setSortByPrice(e.target.value)}
                        >
                            <MenuItem value="none">Not selected</MenuItem>
                            <MenuItem value="high-to-low">High to Low</MenuItem>
                            <MenuItem value="low-to-high">Low to High</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FormControlLabel
                    style={{ width: "fit-content" }}
                    label="Show favorites"
                    checked={showFavorites}
                    onChange={(e) => setShowFavorites(e.target.checked)}
                    control={
                        <Checkbox color="primary" />
                    }
                />
            </div>
        </DialogTitle>
        <DialogContent className="pb-0">
            <div className="row m-0 p-0">
                {/* {props.images.slice((pageSelected - 1) * perPage, pageSelected * perPage)} */}
                {/* filter by image.category == categorySelected if categorySelected != '' */}
                {props.images.filter((item) => {
                    if (categorySelected === 'all') {
                        return true
                    }
                    return item.category === categorySelected
                }).sort((a, b) => {
                    if (sortByPrice === 'high-to-low') {
                        return b.price - a.price
                    } else if (sortByPrice === 'low-to-high') {
                        return a.price - b.price
                    }
                    return 0
                }).slice((pageSelected - 1) * perPage, pageSelected * perPage).map((item, index) => {
                    return <ImageCard windowWidth={windowWidth} key={index} handleUseThisImage={props.handleUseThisImage} item={item}></ImageCard>
                })}
            </div>
        </DialogContent>
        <DialogActions>
            <Pagination className="py-2" color="primary" count={Math.ceil(props.images.filter((item) => { return categorySelected === 'all' || item.category === categorySelected }).length / perPage)} page={pageSelected} onChange={(e, page) => setPageSelected(page)} />
        </DialogActions>
    </Dialog>
}

function ImageCard(props) {
    const [isFavorite, setIsFavorite] = useState(false);
    console.log(props.item)
    return <div className={props.windowWidth < 768 ? "col-12" : "col-4"} style={{
        aspectRatio: "1/1",
        position: "relative",
    }}>
        <CardActionArea style={{
            margin: 10,
            left: 0,
            height: "calc(100% - 20px)",
            width: "calc(100% - 20px)",
            backgroundColor: "lightgray",
            backgroundImage: `url('` + props.item.url + `')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            borderRadius: 23,
            overflow: "hidden",
        }} onClick={() => {
            props.handleUseThisImage(props.item.id)
        }}></CardActionArea>
        <IconButton style={{
            position: "absolute",
            top: 15,
            left: 15,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        }} onClick={() => setIsFavorite(!isFavorite)} color={isFavorite ? "warning" : "default"}>
            <Star />
        </IconButton>
        <div style={{
            position: "absolute",
            top: 15,
            right: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            width: 40,
            borderRadius: "50%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            pointerEvents: "none",
        }}>
            <Https />
        </div>

        <div style={{
            position: "absolute",
            bottom: 18,
            right: 18,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 100,
            paddingInline: 8,
            paddingBlock: 4,
            fontSize: 14,
            fontWeight: 600,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            pointerEvents: "none",
        }}>
            ${props.item.price}
        </div>

        <div style={{
            position: "absolute",
            bottom: 18,
            right: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 100,
            paddingInline: 8,
            paddingBlock: 4,
            fontSize: 14,
            fontWeight: 600,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            pointerEvents: "none",
        }}>
            {props.item.id}
        </div>
    </div>;
}