import { Button, ButtonGroup, CardActionArea, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useEffect, useRef, useState } from "react";
import { ArrowRight, Close, CloseFullscreen, Done, Download, Info, Logout, OpenInFull, Payment, Visibility, VisibilityOff } from "@mui/icons-material";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import AllImagesDialog from "./all-images-dialog";
import { auth } from "../firebase";

import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import axios from "axios";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

export default function ImageViewerDialog(props) {
    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const swiperRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    const [useImageTypeDialogOpen, setUseImageTypeDialogOpen] = useState(false);
    const [useImagePaymentTypeDialogOpen, setUseImagePaymentTypeDialogOpen] = useState(false);
    const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
    const [subscriptionTypeSelected, setSubscriptionTypeSelected] = useState("monthly");
    const [addImagesDialogOpen, setAddImagesDialogOpen] = useState(false);
    const [makeGlobalLoading, setMakeGlobalLoading] = useState(false);
    const [onlyForThisPageLoading, setOnlyForThisPageLoading] = useState(false);
    const [imageFullScreenDialogOpen, setImageFullScreenDialogOpen] = useState(false);
    const [imageFullScreen, setImageFullScreen] = useState(null);
    const [errorText, setErrorText] = useState("");
    const [user, setUser] = useState(null);
    const [signoutConfirmationDialogOpen, setSignoutConfirmationDialogOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageUsedSuccessDialogOpen, setImageUsedSuccessDialogOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        // window listener
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });

        setWindowWidth(window.innerWidth);

        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth);
            });
        }
    }, []);

    const handleUseImage = () => {
        setUseImageTypeDialogOpen(false);
        setUseImagePaymentTypeDialogOpen(true);
    };

    useEffect(() => {
        if (useImagePaymentTypeDialogOpen) {
            axios.get("https://brainteaser.codersuccess.com/api/popup/getSubscriptionsList").then((response) => {
                setAvailableSubscriptions(response.data);
            });
        }
    }, [useImagePaymentTypeDialogOpen]);

    const handleImageSetSuccess = () => {
        setUseImagePaymentTypeDialogOpen(false);
        setImageUsedSuccessDialogOpen(true);
    }

    const subscriptionCreateHandler = (data, actions) => {
        return actions.subscription.create({
            plan_id: 'P-858837539D775530DMY2ZB4Q',
            custom_id: 'please put here user email address you will get it on login'
        });
    };

    const [subscriptionPaypalAnchorEl, setSubscriptionPaypalAnchorEl] = useState(null);
    const subscriptionPaypalOpen = Boolean(subscriptionPaypalAnchorEl);
    const handleSubscriptionPaypalClick = (event) => {
        setSubscriptionPaypalAnchorEl(event.currentTarget);
    };
    const handleSubscriptionPaypalClose = () => {
        setSubscriptionPaypalAnchorEl(null);
    };

    const handleSignInWithPopup = ({
        callback = () => { },
    }) => {
        if (!auth.currentUser) {
            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider)
                .then((result) => {
                    const user = result.user;
                    setUser(user);
                    user.getIdToken().then((idToken) => {
                        axios.post(
                            "https://brainteaser.codersuccess.com/api/auth/google-login",
                            {
                                Token: idToken
                            }
                        ).then((response) => {
                            var token = response.data.token;
                            document.cookie = "token=" + token + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                            callback();
                        })
                    });
                })
                .catch((error) => {
                    setErrorText(error.message);
                    setMakeGlobalLoading(false);
                });
        } else {
            handleUseImage();
            setMakeGlobalLoading(false);
            setUser(auth.currentUser);
        }
    }

    const handleSignOut = () => {
        signOut(auth).then(() => {
            setUser(null);
            setUseImagePaymentTypeDialogOpen(false);
            setSignoutConfirmationDialogOpen(false);
        }).catch((error) => {
            setErrorText(error.message);
        });
    }

    const handleMakeGlobal = () => {
        // if (makeGlobalLoading) return;
        // setMakeGlobalLoading(true);
        // handleSignInWithPopup();
        setUseImageTypeDialogOpen(false);
        handleUseThisImage();
    }

    const handleOnlyForThisPage = () => {
        // if (onlyForThisPageLoading) return;
        // setOnlyForThisPageLoading(true);
        // handleSignInWithPopup();
        setUseImageTypeDialogOpen(false);
        handleUseThisImage();
    }

    const handleAllImagesDialogOpen = () => {
        props.onClose();
        setUseImageTypeDialogOpen(false);
        setAddImagesDialogOpen(true);
    }

    const handleAddImagesDialogClose = () => {
        props.onOpen();
        setAddImagesDialogOpen(false);
    }

    const [first36Images, setFirst36Images] = useState([]);
    const [totalImagesPrevAvailable, setTotalImagesPrevAvailable] = useState(0);
    const [totalImagesNextAvailable, setTotalImagesNextAvailable] = useState(0);
    const [selectedImage, setSelectedImage] = useState(0);

    const handleSlideChange = (index, imagesPreset = []) => {
        var processImages = imagesPreset.length > 0 ? imagesPreset : images;
        var startIndex = 0;
        setSelectedImage(index);

        if (index >= 1) {
            startIndex = index - 1;
        } else {
            startIndex = 0;
        }

        if (startIndex + 36 < processImages.length) {
            setFirst36Images(processImages.slice(startIndex, startIndex + 36));
            setTotalImagesPrevAvailable(startIndex);
            setTotalImagesNextAvailable(processImages.length - (startIndex + 36));
        } else if (startIndex + 36 >= processImages.length) {
            setFirst36Images(processImages.slice(processImages.length - 36, processImages.length));
            setTotalImagesNextAvailable(0);
        } else {
            setFirst36Images(processImages.slice(processImages.length - 36, processImages.length));
        }
    }

    useEffect(() => {
        if (props.open) {
            setImages([]);
            setLoading(true);
            axios.get("https://brainteaser.codersuccess.com/api/popup/getImages").then((response) => {
                setImages(response.data);
                setLoading(false);
                handleSlideChange(0, response.data);
            });
        }
    }, [props.open]);

    const handleUseThisImage = (imageId = null) => {
        // https://brainteaser.codersuccess.com/api/popup/useThisImage

        // check if logged in
        if (!auth.currentUser) {
            handleSignInWithPopup({
                callback: () => {
                    handleUseThisImage();
                },
            });
        } else {
            // set token in header bearer
            var cookies = document.cookie;
            var token = "";

            if (typeof cookies !== "undefined") {
                token = cookies.split(";").find((c) => c.includes("token")).split("=")[1];
            } else {
                handleSignInWithPopup({
                    callback: () => {
                        handleUseThisImage();
                    },
                });
            }

            axios.post("https://brainteaser.codersuccess.com/api/popup/useThisImage", {
                imageId: images[selectedImage].id ?? imageId,
            }, {
                headers: {
                    "Authorization": "Bearer " + document.cookie.split(";").find((c) => c.includes("token")).split("=")[1],
                },
            }).then((response) => {
                if (response.data.message == "buy image or category") {
                    setUser(auth.currentUser);
                    setUseImagePaymentTypeDialogOpen(true);
                } else {
                    setImageUsedSuccessDialogOpen(true);
                }
            });
        }

        // setUseImageTypeDialogOpen(true);
    };

    return <>
        <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
            }}
        >
            <DialogTitle style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                {showSettings ? <div style={{
                    display: "flex",
                    alignItems: windowWidth < 768 ? "start" : "center",
                    gap: 10,
                    flexDirection: windowWidth < 768 ? "column" : "row",
                }}>
                    <Button variant="contained" style={{ gap: 5 }} color="primary" onClick={handleUseThisImage}>
                        use this image <Download />
                    </Button>
                    <Button variant="outlined" onClick={handleAllImagesDialogOpen} style={{ color: "white", borderColor: "white" }}>
                        show all images <ArrowRight />
                    </Button>
                </div> : <div></div>}
                {!showSettings && <Button variant="contained" style={{ gap: 5, position: "fixed", top: 65, right: 10 }} color={showSettings ? "error" : "primary"} onClick={() => {
                    setShowSettings(!showSettings);
                }}>{
                        showSettings ? "Hide settings" : "Show settings"
                    }
                    {showSettings ? <VisibilityOff /> : <Visibility />}</Button>}

            </DialogTitle>
            <DialogContent>
                {loading && <div style={{
                    aspectRatio: "16/9",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#f5f5f5",
                }}>
                    <CircularProgress></CircularProgress>
                </div>}
                {!loading && <Swiper
                    ref={swiperRef}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    onSlideChange={(e) => {
                        handleSlideChange(e.activeIndex);
                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Navigation]}
                    navigation
                >
                    {images.map((image, index) => {
                        return <SwiperSlide key={index}>
                            <div style={{
                                aspectRatio: windowWidth < 768 ? "1/1" : "16/9",
                                backgroundImage: "url('" + image.url + "')",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                width: "100%",
                                backgroundColor: "#f5f5f5",
                            }}>
                            </div>
                            <IconButton color="primary" style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                            }} onClick={() => {
                                setImageFullScreenDialogOpen(true);
                                setImageFullScreen(image);
                                document.documentElement.requestFullscreen();
                            }}>
                                <OpenInFull />
                            </IconButton>
                        </SwiperSlide>;
                    })}
                </Swiper>}

                {showSettings && <div className="row m-0 p-0 mt-3">
                    {(first36Images).map((image, index) => {
                        return <div key={index} className={windowWidth < 768 ? "col-2 p-0" : "col-1 p-0"} style={{
                            borderStyle: "solid",
                            borderWidth: 2,
                            borderColor: images[selectedImage] && (images[selectedImage].id === image.id) ? "red" : "white",
                        }} onClick={() => {
                            var index = images.findIndex((img) => img.id === image.id);
                            swiperRef.current.swiper.slideTo(index);
                        }}>
                            <div style={{
                                aspectRatio: "1/1",
                                backgroundImage: "url('" + image.url + "')",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                width: "100%",
                                backgroundColor: "#f5f5f5",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {
                                    index == 0 && totalImagesPrevAvailable != 0 ? <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                        width: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        color: "white",
                                    }}>
                                        +{totalImagesPrevAvailable}
                                    </div> : index == 35 && totalImagesNextAvailable != 1 && totalImagesNextAvailable != 0 ? <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                        width: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        color: "white",
                                    }}>
                                        +{totalImagesNextAvailable}
                                    </div> : <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                        width: "100%",
                                    }}>
                                        {image.id}
                                    </div>
                                }
                            </div>
                        </div>;
                    })}
                </div>}

                {/* {showSettings && <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    marginTop: 15,
                }}>
                    <Button variant="outlined" onClick={handleAllImagesDialogOpen} style={{ color: "white", borderColor: "white" }}>
                        make global
                    </Button>
                    <Button variant="outlined" onClick={handleAllImagesDialogOpen} style={{ color: "white", borderColor: "white" }}>
                        only for this page
                    </Button>
                </div>} */}
            </DialogContent>
        </Dialog>

        <Dialog open={useImageTypeDialogOpen} onClose={() => setUseImageTypeDialogOpen(false)} fullWidth>
            <DialogTitle style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                Use this image
                <IconButton color="error" onClick={() => setUseImageTypeDialogOpen(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                }}>
                    How would you like to use this image?
                    <div style={{
                        paddingInline: 20,
                        paddingBlock: 15,
                        borderRadius: 10,
                        display: "flex",
                        gap: 10,
                        backgroundColor: "#fff1b5",
                        color: "#7a7a7a",
                    }}>
                        <Info color="warning" className="mt-1" />
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                        }}>
                            <p className="m-0 p-0">
                                <strong>Global:</strong> This image will be used in all pages.
                            </p>
                            <p className="m-0 p-0">
                                <strong>Only for this page:</strong> This image will be used only in this page.
                            </p>
                        </div>
                    </div>
                    {/* <div style={{
                        display: "flex",
                        gap: 10,
                    }}>
                        <Button variant="contained" onClick={handleMakeGlobal}>
                            {makeGlobalLoading ? <CircularProgress style={{ color: "#fff" }} size={24} /> : "Make Global"}
                        </Button>
                        <Button variant="contained" onClick={handleOnlyForThisPage}>
                            {onlyForThisPageLoading ? <CircularProgress style={{ color: "#fff" }} size={24} /> : "Only for this page"}
                        </Button>
                    </div> */}
                </DialogContentText>
            </DialogContent>
        </Dialog>


        <Dialog open={useImagePaymentTypeDialogOpen} onClose={() => setUseImagePaymentTypeDialogOpen(false)} fullWidth maxWidth="md" PaperProps={{ style: { borderRadius: 15, backgroundColor: "#f7f7f7" } }}>
            <DialogTitle style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <span>
                    <Payment color="primary" className="me-2" />
                    Payment type
                </span>
                <IconButton color="error" onClick={() => setUseImagePaymentTypeDialogOpen(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                    fontSize: 15,
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        display: "flex",
                        alignItems: "center",
                        flexDirection: windowWidth < 768 ? "column" : "row",
                        gap: 20,
                        borderRadius: 10,
                        paddingInline: 20,
                        paddingBlock: 15,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#969696",
                    }}>
                        {user && <div style={{
                            height: 50,
                            width: 50,
                            backgroundImage: "url('" + user.photoURL + "')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: 100,
                            backgroundColor: "#4d4d4d",
                        }}></div>}
                        {user && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: windowWidth < 768 ? "center" : "start",
                            gap: 0,
                            flexGrow: 1,
                        }}>
                            <span style={{
                                color: "#1876d2",
                                fontSize: 18,
                            }}>
                                <strong>{user.displayName}</strong>
                            </span>
                            <span style={{
                                color: "#969696",
                                fontSize: 13,
                                fontWeight: "normal",
                            }}>
                                <strong>{user.email}</strong>
                            </span>
                        </div>}
                        {user && <Button variant="contained" onClick={() => {
                            setSignoutConfirmationDialogOpen(true);
                        }} style={{
                            gap: 5,
                        }}>
                            <Logout /> Sign Out
                        </Button>}
                    </div>
                    How would you like to pay for this image? Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

                    <ButtonGroup variant="contained" style={{
                        width: "fit-content",
                        margin: "auto",
                        backgroundColor: "white",
                    }}>
                        <Button variant={subscriptionTypeSelected === "monthly" ? "contained" : "outlined"} onClick={() => setSubscriptionTypeSelected("monthly")}>
                            Monthly
                        </Button>
                        <Button variant={subscriptionTypeSelected === "yearly" ? "contained" : "outlined"} onClick={() => setSubscriptionTypeSelected("yearly")}>
                            Yearly
                        </Button>
                    </ButtonGroup>

                    <div className="row m-0 p-0 mb-0">
                        {availableSubscriptions.map((subscription, index) => {
                            return <div className={windowWidth < 768 ? "col-12 p-3 column" : "col-4 p-3 column"} key={index}>
                                <div className="w-100 mb-3" style={{
                                    boxShadow: "0px 0px 10px 0px #cccccc",
                                    borderRadius: 15,
                                    backgroundColor: "white",
                                    paddingInline: 20,
                                    paddingBlock: 15,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    borderStyle: "solid",
                                    borderWidth: 2,
                                    borderColor: index == 1 ? "#ff6903" : "#fff",
                                    position: "relative",
                                    height: 190,
                                }}>
                                    {index == 1 && <Player
                                        autoplay={true}
                                        src={"/animations/subscription-lottie-animation.json"}
                                        style={{
                                            height: "100%",
                                            aspectRatio: "1/1",
                                            height: 50,
                                            width: 50,
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                        }}
                                        loop={true}
                                    >
                                        <Controls visible={false} />
                                    </Player>}
                                    <label style={{
                                        fontSize: 18,
                                        color: "black",
                                    }}>
                                        <strong>{subscription.name}</strong>
                                    </label>
                                    <label style={{
                                        fontSize: 14,
                                        color: "#969696",
                                    }}>
                                        {subscription.description ?? "No description"}
                                    </label>
                                    <div style={{
                                        flexGrow: 1,
                                    }}></div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "center",
                                        gap: 5,
                                    }}>
                                        <span
                                            style={{
                                                color: "#b9c2d2",
                                                marginTop: 5,
                                                marginLeft: 20,
                                            }}
                                        >$</span>
                                        <strong style={{
                                            color: "black",
                                            fontSize: 30,
                                        }}>
                                            {subscriptionTypeSelected === "yearly" ? subscription.priceYear : subscription.priceMonth}
                                        </strong>
                                        <span
                                            style={{
                                                color: "#b9c2d2",
                                                marginTop: 15,
                                                fontWeight: "bold",
                                            }}
                                        >/{
                                                subscriptionTypeSelected === "yearly" ? "year" : "month"

                                            }</span>
                                    </div>
                                    <div style={{
                                        flexGrow: 1,
                                    }}></div>
                                    <Button variant="contained" style={{
                                        backgroundColor: "#1876d2",
                                        color: "#fff",
                                        width: "100%",
                                        backgroundImage: index == 1 ? "linear-gradient(45deg, #ff6903, #febe2b)" : "none",
                                        backgroundColor: index == 1 ? "transparent" : "#1876d2",
                                        boxShadow: "0px 0px 0px 0px #cccccc",
                                    }} onClick={handleSubscriptionPaypalClick}>
                                        <strong>Buy now</strong>
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={subscriptionPaypalAnchorEl}
                                        open={subscriptionPaypalOpen}
                                        onClose={handleSubscriptionPaypalClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <div style={{
                                            width: 300,
                                            paddingInline: 15,
                                        }}>
                                            <PayPalScriptProvider options={{
                                                'client-id': 'AfSQC_2VBPu-IlVoGbQe-r7K8iZuEjlG9gzQkgHw19gEJZSjWUb7-nSpZ5eoWRIexd5qW9IqrfFcWh1D',
                                                vault: true,
                                                intent: 'subscription'
                                            }}>
                                                <PayPalButtons
                                                    style={{
                                                        layout: 'vertical',
                                                        color: 'gold',
                                                        shape: 'rect',
                                                        label: 'subscribe'
                                                    }}
                                                    onApprove={handleImageSetSuccess}
                                                    createSubscription={subscriptionCreateHandler}
                                                    onCancel={() => {
                                                        setErrorText("Subscription cancelled");
                                                    }}
                                                    onError={() => {
                                                        setErrorText("Subscription error");
                                                    }}
                                                />
                                            </PayPalScriptProvider>
                                        </div>
                                    </Menu>
                                </div>
                                {subscription.descriptionList.map((description, index) => {
                                    return <div key={index} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                        marginTop: 5,
                                    }}>
                                        <Done color="primary" />
                                        <label style={{
                                            color: "#969696",
                                        }}>
                                            {description}
                                        </label>
                                    </div>
                                })}
                            </div>;
                        })}
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: 27,
                        position: "relative",
                    }}>
                        <div style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d4d4d4",
                        }}></div>
                        <div style={{
                            position: "absolute",
                            paddingInline: 15,
                            height: "100%",
                            backgroundColor: "#f7f7f7",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <strong>OR</strong>
                        </div>
                    </div>

                    <CardActionArea style={{
                        width: windowWidth < 768 ? "100%" : 450,
                        flexDirection: windowWidth < 768 ? "column" : "row",
                        margin: "auto",
                        display: "flex",
                        borderRadius: 15,
                        // gradient border
                        border: "1px solid",
                        borderColor: "#1876d2",
                        gap: 15,
                        paddingRight: 15,
                        paddingBlock: 15,
                        justifyContent: "start",
                        paddingInline: 15,
                        backgroundColor: "white",
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: windowWidth < 768 ? "center" : "start",
                            gap: 3,
                        }}>
                            <label style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#1876d2",
                            }}>
                                One-time purchase
                            </label>
                            <p className="m-0 p-0" style={{
                                color: "#969696",
                                fontSize: 14,
                            }}>
                                Buy this image for a one-time fee.
                            </p>
                        </div>
                        <div style={{
                            flexGrow: 1,
                        }}></div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                        }}>
                            <span style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                color: "#1876d2",
                            }}>
                                $5.99
                            </span>
                            <div style={{
                                height: 40,
                                paddingInline: 15,
                                backgroundColor: "#1876d2",
                                width: "fit-content",
                                borderRadius: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                            }}>
                                <strong>Buy now</strong>
                            </div>
                        </div>
                    </CardActionArea>
                </DialogContentText>
            </DialogContent>
        </Dialog>

        <Snackbar
            open={errorText.length > 0}
            autoHideDuration={4000}
            onClose={() => setErrorText("")}
            message={errorText}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setErrorText("")}>
                    <Close fontSize="small" />
                </IconButton>
            }
        />

        <Dialog open={signoutConfirmationDialogOpen} onClose={() => setSignoutConfirmationDialogOpen(false)} fullWidth>
            <DialogTitle>
                Sure?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to sign out?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSignoutConfirmationDialogOpen(false)} color="inherit">
                    No
                </Button>
                <Button onClick={handleSignOut} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={imageUsedSuccessDialogOpen} onClose={() => setImageUsedSuccessDialogOpen(false)}>
            <DialogContent style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <Player
                    autoplay={true}
                    src={"/animations/celebration.json"}
                    style={{
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                    loop={false}
                >
                    <Controls visible={false} />
                </Player>
                <Player
                    autoplay={true}
                    src={"/animations/celebration.json"}
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        transform: "rotate(180deg)",
                    }}
                    loop={false}
                >
                    <Controls visible={false} />
                </Player>
                <Player
                    autoplay={true}
                    src={"/animations/success.json"}
                    style={{
                        width: 300,
                        height: 300,
                        marginTop: -60,
                        top: 0,
                        right: 0,
                    }}
                    loop={false}
                    keepLastFrame
                >
                    <Controls visible={false} />
                </Player>
                <DialogContentText style={{ marginTop: -60, width: 300, textAlign: "center", display: "flex", flexDirection: "column" }}>
                    <label style={{
                        color: "black",
                        fontSize: 22,
                        fontWeight: "bold",
                        marginBottom: 10,
                    }}>Image used successfully</label>
                    <span style={{ color: "#969696", fontSize: 13 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </span>
                    <Button variant="contained" className="w-100 mt-3" onClick={() => setImageUsedSuccessDialogOpen(false)}>
                        Close
                    </Button>
                </DialogContentText>
            </DialogContent>
        </Dialog>

        <AllImagesDialog images={images} open={addImagesDialogOpen} handleUseThisImage={handleUseThisImage} onClose={handleAddImagesDialogClose} setUseImageTypeDialogOpen={setUseImageTypeDialogOpen} />

        <Dialog open={imageFullScreenDialogOpen} onClose={() => {
            setImageFullScreenDialogOpen(false);
            document.exitFullscreen();
        }} fullScreen>
            <DialogContent style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: 0,
                backgroundColor: "black",
                position: "relative",
            }}>
                <img src={imageFullScreen?.url} style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                }} />
                <IconButton color="primary" style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                }} onClick={() => {
                    setImageFullScreenDialogOpen(false);
                    document.exitFullscreen();
                }}>
                    <CloseFullscreen />
                </IconButton>
            </DialogContent>
        </Dialog>
    </>;
}